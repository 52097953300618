<template>
  <div v-if="showStatisticCard">
    <div class="row">
      <statistics-card
        v-if="boxPendingPickup"
        :clickable="clickable"
        :is-loading="isLoading.PendingPickup"
        title="Pending Pickup"
        :count="totalPendingPickup"
        :percentage="percentagePendingPickupCOD"
        menu="All time"
        :dropdown="[
          {
            label: 'All time',
            route: '#',
          },
        ]"
        :is_active="is_active.PendingPickup"
        @click.prevent="handleViewPackageDetail('pending-pickup')"
      />
      <statistics-card
        v-if="boxOnProgress"
        :clickable="clickable"
        :is-loading="isLoading.OnProgress"
        title="Sedang Diperjalanan"
        :count="totalOnProgress"
        :percentage="percentageOnProgressCOD"
        menu="All time"
        :dropdown="[
          {
            label: 'All time',
            route: '#',
          },
        ]"
        :is_active="is_active.OnProgress"
        @click.prevent="handleViewPackageDetail('on-progress')"
      />
      <statistics-card
        v-if="boxSuccessfulDelivery"
        :clickable="clickable"
        :is-loading="isLoading.SuccessfulDelivery"
        title="Berhasil"
        :count="totalSuccessfulDelivery"
        :percentage="percentageSuccessfulDeliveryCOD"
        menu="All time"
        :dropdown="[
          {
            label: 'All time',
            route: '#',
          },
        ]"
        :is_active="is_active.SuccessfulDelivery"
        @click.prevent="handleViewPackageDetail('successful-delivery')"
      />
    </div>
    <div class="row mt-2">
      <statistics-card
        v-if="boxPendingReschedule"
        :clickable="clickable"
        :is-loading="isLoading.PendingReschedule"
        title="Penjadwalan Ulang"
        :count="totalPendingReschedule"
        :percentage="percentagePendingRescheduleCOD"
        menu="All time"
        :dropdown="[
          {
            label: 'All time',
            route: '#',
          },
        ]"
        :is_active="is_active.PendingReschedule"
        @click.prevent="handleViewPackageDetail('pending-reschedule')"
      />
      <statistics-card
        v-if="boxOnHold"
        :clickable="clickable"
        :is-loading="isLoading.OnHold"
        title="Tertahan"
        :count="totalOnHold"
        :percentage="percentageOnHoldCOD"
        menu="All time"
        :dropdown="[
          {
            label: 'All time',
            route: '#',
          },
        ]"
        :is_active="is_active.OnHold"
        @click.prevent="handleViewPackageDetail('on-hold')"
      />
      <statistics-card
        v-if="boxReturnToSender"
        :clickable="clickable"
        :is-loading="isLoading.ReturnToSender"
        title="Gagal"
        :count="totalReturnToSender"
        :percentage="percentageReturnToSenderCOD"
        menu="All time"
        :dropdown="[
          {
            label: 'All time',
            route: '#',
          },
        ]"
        :is_active="is_active.ReturnToSender"
        @click.prevent="handleViewPackageDetail('return-to-sender')"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import StatisticsCard from "@/components/StatisticsCard.vue";
export default {
  name: "OverviewBoxOrder",
  components: {
    StatisticsCard,
  },
  props: {
    is_admin: { required: false, type: Boolean, default: false },
    clickable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showStatisticCard: true,

      boxPendingPickup: true,
      boxOnProgress: true,
      boxSuccessfulDelivery: true,
      boxPendingReschedule: true,
      boxReturnToSender: true,
      boxOnHold: true,

      dataPendingPickup: [],
      dataOnProgress: [],
      dataSuccessfulDelivery: [],
      dataPendingReschedule: [],
      dataReturnToSender: [],
      dataOnHold: [],

      totalPendingPickup: 0,
      totalOnProgress: 0,
      totalSuccessfulDelivery: 0,
      totalPendingReschedule: 0,
      totalReturnToSender: 0,
      totalOnHold: 0,

      percentagePendingPickupCOD: {
        color: "primary",
        value: "0%",
        label: "Jenis Paket COD",
      },
      percentageOnProgressCOD: {
        color: "success",
        value: "0%",
        label: "Jenis Paket COD",
      },
      percentageSuccessfulDeliveryCOD: {
        color: "success",
        value: "0%",
        label: "Jenis Paket COD",
      },
      percentagePendingRescheduleCOD: {
        color: "danger",
        value: "0%",
        label: "Jenis Paket COD",
      },
      percentageReturnToSenderCOD: {
        color: "danger",
        value: "0%",
        label: "Jenis Paket COD",
      },
      percentageOnHoldCOD: {
        color: "danger",
        value: "0%",
        label: "Jenis Paket COD",
      },

      // if member
      urlread_pending_pickup: "/api/v1/send_packages/pending_pickup",
      urlread_onprogress: "/api/v1/send_packages/onprogress",
      urlread_successful_delivery: "/api/v1/send_packages/successful_delivery",
      urlread_pending_reschedule: "/api/v1/send_packages/pending_reschedule",
      urlread_return_to_sender: "/api/v1/send_packages/return_to_sender",
      urlread_on_hold: "/api/v1/send_packages/on_hold",

      // if member
      urlread_pending_pickup_admin: "/api/v1/send_packages/admin/pending_pickup",
      urlread_onprogress_admin: "/api/v1/send_packages/admin/onprogress",
      urlread_successful_delivery_admin: "/api/v1/send_packages/admin/successful_delivery",
      urlread_pending_reschedule_admin: "/api/v1/send_packages/admin/pending_reschedule",
      urlread_return_to_sender_admin: "/api/v1/send_packages/admin/return_to_sender",
      urlread_on_hold_admin: "/api/v1/send_packages/admin/on_hold",

      isLoading: {
        PendingPickup: false,
        OnProgress: false,
        SuccessfulDelivery: false,
        PendingReschedule: false,
        ReturnToSender: false,
        OnHold: false,
      },
      is_active: {
        PendingPickup: false,
        OnProgress: false,
        SuccessfulDelivery: false,
        PendingReschedule: false,
        ReturnToSender: false,
        OnHold: false,
      },
    };
  },
  created() {
    this.getPendingPickup();
    this.getOnProgress();
    this.getSuccessfulDelivery();
    this.getPendingReschedule();
    this.getReturnToSender();
    this.getOnHold();
  },
  methods: {
    async getPendingPickup() {
      try {
        this.isLoading.PendingPickup = true;
        let url = this.is_admin == true ? this.urlread_pending_pickup_admin : this.urlread_pending_pickup;
        const response = await axios.get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.dataPendingPickup = response.data.data.data;
        this.totalPendingPickup = response.data.data.total;
        this.percentagePendingPickupCOD.value =
          response.data.data.display_total_percentage_cod;
        this.isLoading.PendingPickup = false;
      } catch (error) {
        console.log(error);
        this.isLoading.PendingPickup = false;
      }
    },
    async getOnProgress() {
      try {
        this.isLoading.OnProgress = true;
        let url = this.is_admin == true ? this.urlread_onprogress_admin : this.urlread_onprogress;
        const response = await axios.get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.dataOnProgress = response.data.data.data;
        this.totalOnProgress = response.data.data.total;
        this.percentageOnProgressCOD.value =
          response.data.data.display_total_percentage_cod;
        this.isLoading.OnProgress = false;
      } catch (error) {
        console.log(error);
        this.isLoading.OnProgress = false;
      }
    },
    async getSuccessfulDelivery() {
      try {
        this.isLoading.SuccessfulDelivery = true;
        let url = this.is_admin == true ? this.urlread_successful_delivery_admin : this.urlread_successful_delivery;
        const response = await axios.get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.dataSuccessfulDelivery = response.data.data.data;
        this.totalSuccessfulDelivery = response.data.data.total;
        this.percentageSuccessfulDeliveryCOD.value =
          response.data.data.display_total_percentage_cod;
        this.isLoading.SuccessfulDelivery = false;
      } catch (error) {
        console.log(error);
        this.isLoading.SuccessfulDelivery = false;
      }
    },
    async getPendingReschedule() {
      try {
        this.isLoading.PendingReschedule = true;
        let url = this.is_admin == true ? this.urlread_pending_reschedule_admin : this.urlread_pending_reschedule;
        const response = await axios.get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.dataPendingReschedule = response.data.data.data;
        this.totalPendingReschedule = response.data.data.total;
        this.percentagePendingRescheduleCOD.value =
          response.data.data.display_total_percentage_cod;
        this.isLoading.PendingReschedule = false;
      } catch (error) {
        console.log(error);
        this.isLoading.PendingReschedule = false;
      }
    },
    async getReturnToSender() {
      try {
        this.isLoading.ReturnToSender = true;
        let url = this.is_admin == true ? this.urlread_return_to_sender_admin : this.urlread_return_to_sender;
        const response = await axios.get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.dataReturnToSender = response.data.data.data;
        this.totalReturnToSender = response.data.data.total;
        this.percentageReturnToSenderCOD.value =
          response.data.data.display_total_percentage_cod;
        this.isLoading.ReturnToSender = false;
      } catch (error) {
        console.log(error);
        this.isLoading.ReturnToSender = false;
      }
    },

    async getOnHold() {
      try {
        this.isLoading.OnHold = true;
        let url = this.is_admin == true ? this.urlread_on_hold_admin : this.urlread_on_hold;
        const response = await axios.get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.dataOnHold = response.data.data.data;
        this.totalOnHold = response.data.data.total;
        this.percentageOnHoldCOD.value =
          response.data.data.display_total_percentage_cod;
        this.isLoading.OnHold = false;
      } catch (error) {
        console.log(error);
        this.isLoading.OnHold = false;
      }
    },

    handleViewPackageDetail(type) {
      this.normalBoxLink();
      let values = "";
      switch (type) {
        case "pending-pickup":
          values = this.dataPendingPickup;
          this.is_active.PendingPickup = true;
          break;
        case "on-progress":
          values = this.dataOnProgress;
          this.is_active.OnProgress = true;
          break;
        case "successful-delivery":
          values = this.dataSuccessfulDelivery;
          this.is_active.SuccessfulDelivery = true;
          console.log('successful-delivery')
          console.log(values)
          break;
        case "pending-reschedule":
          values = this.dataPendingReschedule;
          this.is_active.PendingReschedule = true;
          break;
        case "on-hold":
          values = this.dataOnHold;
          this.is_active.OnHold = true;
          break;
        case "return-to-sender":
          values = this.dataReturnToSender;
          this.is_active.ReturnToSender = true;
          console.log('return to sender')
          console.log(values)
          break;
      }

      this.showStatisticCard = false;
      setTimeout(() => {
        this.showStatisticCard = true;
      }, 1);

      this.$emit("viewdetailpackage", values);
    },

    normalBoxLink() {
      this.is_active.PendingPickup = false;
      this.is_active.OnProgress = false;
      this.is_active.SuccessfulDelivery = false;
      this.is_active.PendingReschedule = false;
      this.is_active.ReturnToSender = false;
      this.is_active.OnHold = false;
    },
  },
};
</script>

<style></style>
