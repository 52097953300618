<template>
  <argon-button
    v-if="showButton"
    variant="outline"
    color="primary"
    :loading="isLoading"
    @click.prevent="handlePrintAirWayBill"
    ><i class="fas fa-print" style="font-size: 18px"></i>&nbsp;&nbsp;Print
    Airway Bill</argon-button
  >
  <form id="TheForm" method="post" :action="urlpost" target="TheWindow">
    <input type="hidden" name="data" :value="data" />
  </form>
</template>

<script>
import axios from "axios";
import ArgonButton from "@/components/ArgonButton.vue";
export default {
  name: "PrintAirWayBillSelectedButton",
  components: {
    ArgonButton,
  },
  props: {
    data: { required: true, type: Object },
  },
  data() {
    return {
      basePath: axios.defaults.baseURL,
      urlPDFSelectedAirwayBill: "/api/v1/pdf/selectedairwaybill",
      urlpost: "",
      isLoading: false,
      showButton: true,
    };
  },
  methods: {
    handlePrintAirWayBill() {
      let process_type = "from own pdf";
      //let process_type = "from own ninja";

      if (process_type == "from own pdf") {
        this.urlpost = this.basePath + "/" + this.urlPDFSelectedAirwayBill;
        this.openWindowWithPost(this.urlpost, {
          data: this.data,
          token: localStorage.getItem("token"),
        });
      } else if (process_type == "from own ninja") {
        console.log("from own ninja");
      }
    },
    openWindowWithPost(url, data) {
      let form = document.createElement("form");
      form.target = "_blank";
      form.method = "POST";
      form.action = url;
      form.style.display = "none";

      for (let key in data) {
        let input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = data[key];
        form.appendChild(input);
      }
      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    },
  },
};
</script>
